.endorsal-card {
  display: flex;
  padding: 25px;
  flex-direction: column;
  box-shadow: 1px 1px 10px rgb(204, 204, 204);
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
  .avatar {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
    box-shadow: 1px 1px 20px rgb(204, 204, 204);
  }

  .rating {
    img {
      height: 30px;
      margin-right: 2px;
    }
  }
  h3 {
    font-size: 21px;
    font-weight: 700;
    text-align: center;
  }
  h4 {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 10px;
    color: #777777;
  }
  p {
    //   text-align: center;
    margin-bottom: 10px;
    color: #777777;

    span {
      button {
        background: #ffdc64;
        border: none;
        padding: 3px;
        border-radius: 5px;
        margin-left: 5px;
        color: #000;
        cursor: pointer;
      }
    }
  }
  .logo {
    height: 70px;
    margin-bottom: 10px;
  }
  .review {
    font-size: 12px;
    position: absolute;
    bottom: 5px;
    right: 5%;
  }
}
